import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class Services extends Component {
    constructor(props){
        super(props);
        this.state = {
           team: [
                {
                    id: 1,
                    img: 'assets/images/services/1.png',
                    title: 'Health',
                    
                },
                {
                    id: 2,
                    img: 'assets/images/services/2.png',
                    title: 'Disaster Management',
                   
                },
                {
                    id: 3,
                    img: 'assets/images/services/3.png',
                    title: 'Education',
                   
                },
                {
                    id: 4,
                    img: 'assets/images/services/4.png',
                    title: 'Clean Water',
                    
                },
                {
                    id: 5,
                    img: 'assets/images/services/5.png',
                    title: 'Orphan Care Program',
                   
                },
                {
                    id: 6,
                    img: 'assets/images/services/6.png',
                    title: 'Aghosh',
                   
                },
                {
                    id: 7,
                    img: 'assets/images/services/7.png',
                    title: 'Mawakhat',
                   
                },
                {
                    id: 8,
                    img: 'assets/images/services/8.png',
                    title: 'Community Services',
                   
                },
                
            ]
        }
    }

    render() {
        return (
            <section className="team section-padding">
                <div className="container">
                    <div className="row  mb-40">
                        <div className="col-lg-12">
                            <div className="section-heading  text-lg-start">
                                <h2 className="font-lg text-center">Areas of Service</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                    {
                        this.state.team.map((data,i) =>(

                            <div className="col-xl-3 col-lg-4 col-sm-6" key={data.id}>
                                <div className="team-item team-item-4 mb-70 mb-lg-0">
                                    <div className="team-img">
                                        <img src={data.img} alt="" className="img-fluid"/>

                                    </div>
                                    <div className="team-content">
                                        <div className="team-info">
                                            <h5>{data.title}</h5>
                                        </div>

                                       
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                    
                    </div>
                </div>
            </section>

           
                    
        );
    }
}

export default Services;
