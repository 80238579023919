import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Cta extends Component {
    render() {
        return (
            <section className="cta-5 section-padding bg-gray">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-12">
                            <div className="cta-inner4">
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-xl-6 col-lg-6">
                                        <div className="cta-img mb-4 mb-lg-0">
                                            <img src="assets/images/about/Bano-Qabil-Banner.jpg" alt="" className="img-fluid"/>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6">
                                        <div className="cta-content ps-lg-4">
                                            <h2 className="mb-20">WHO WE ARE</h2>
                                            <p>Alkhidmat Foundation Pakistan is a globally recognized non-profit organization that was established in 1990 by a group of like-minded individuals who were inspired by the teachings of Islam and its emphasis on helping the poor, needy, and vulnerable members of society. The organization was formally registered as a non-profit in 1993 and since then has been providing relief and development services to the most deprived and marginalized communities in Pakistan.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Cta;
