import React, { Component } from 'react';
import Slider from "react-slick";
import { HomeTestimonial } from '../../../utils/script';

class Review extends Component {
    constructor(props){
        super(props);
        this.state = {
            testimonial: [
                {
                    id: 1,
                    img: 'assets/images/clients/testimonial-avata-01.jpg',
                    text: 'Currently enrolled in the Amazon VA course at Bahadurabad campus and the past 2 months have been great Alhamdulillah! From our course instructor to amazing batch mates, everyone is very hard working and helpful! definitely recommended.',
                    name: 'Saleha Siddiqui',
                    desigantion: 'Student',
                },
                {
                    id:2,
                    img: 'assets/images/clients/testimonial-avata-02.jpg',
                    text: 'Bano Qabil is an exceptional initiative by Alkhidmat Foundation that aims to empower the youth. The program ensures the proper maintenance of every aspect, from conducting aptitude tests to organizing physical classes. We are truly blessed to have access to such outstanding teachers and mentors who provide us with their invaluable guidance and support.',
                    name: 'Aisha Saqib',
                    desigantion: 'Student',
                },
                {
                    id: 3,
                    img: 'assets/images/clients/testimonial-avata-03.jpg',
                    text: 'Good opportunity for those people who cannot afford IT courses. Thanks to Alkhidmat team Allah sb ko kamiyab kary Ameen.',
                    name: 'Nabeeha Saleem',
                    desigantion: 'Student',
                },
            ]
        }
    }

    render() {
        
        return (

            <section className="testimonial-4 section-padding bg-gray">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8">
                            <div className="section-heading text-center mb-50">
                                <h2 className="font-lg">Testimonials</h2>
                                <p>Heartwarming reviews from our Alkhidmat Bano Qabil Program students! It’s incredibly fulfilling to see our efforts translate into meaningful progress for our students. We’re honored to be a part of their journey towards success and empowerment.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-xl-12">
                            <div className="testimonials-slides">
                                <Slider {...HomeTestimonial} >
                                {
                                    this.state.testimonial.map((data,i) => (
                                        <div class="testimonial-item" key={data.id}>
                                            <div class="testimonial-inner">
                                                <div class="quote-icon"><i class="flaticon-left-quote"></i></div>
                                                
                                                    <div class="testimonial-text mb-30">
                                                        {data.text}
                                                    </div>

                                                    <div class="client-info d-flex align-items-center">
                                                        <div class="client-img">
                                                            <img src={data.img} alt="" class="img-fluid"/>
                                                        </div>
                                                        <div class="testimonial-author">
                                                            <h4>{data.name}</h4>
                                                            <span class="meta">{data.desigantion}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        ))
                                    }
                                    
                                </Slider>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
       
        );
    }
}

export default Review;
