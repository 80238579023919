import React from 'react';

import HeaderOne from '../../common/header/HeaderOne';
import FooterOne from '../../common/footer/FooterOne';

import {Banner, Counter, CourseGrid01,CourseCat01,About, Features,Team,Review,Cta}  from '../layouts/home01/index';
import { CourseGridPopular } from '../layouts/home02';
import Services from '../layouts/home01/Services';


const HomeOne = () => {
    return (
        <div className="home-page">       
            <HeaderOne/>
                <Banner />
                <About />
                <Features />
                {/* <Counter /> */}
                <CourseGridPopular/>
                {/* <CourseGrid01 />
                <CourseCat01 /> */}
                
                {/* <CourseGridPopular /> */}
               
                {/* <Team /> */}
                <Cta />    
                <Services/>
                <Review />
                
            <FooterOne/>  
        </div>
    )
}

export default HomeOne;