import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { MainBanner } from '../../../utils/script';

class Banner extends Component {

    render() {
        return (
            <section className='banner-slider'>
                <Slider className='banner-items' {...MainBanner} >
                    <div className='item'>
                        <img src="assets/images/banner/1.jpg" alt="Banner" className='img-fluid' />
                    </div>
                    <div className='item'>
                        <img src="assets/images/banner/2.jpg" alt="Banner" className='img-fluid' />
                    </div>
                    <div className='item'>
                        <img src="assets/images/banner/3.jpg" alt="Banner" className='img-fluid' />
                    </div>
                </Slider>
            </section>

        );
    }
}

export default Banner;

